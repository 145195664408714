$(document).foundation();

$(function () {
  // global
  initSliders();
  initSticky();
  initNotifications();
  initAccordioms();
  initForms();
  initTracking();
  initCurrency();
  initSupport();
  initReportWidget();

  // template specific
  initHomeDemoReport();
  blogFilter();
  initHomepageFAQ();
});

function blogFilter() {
  // Redirect to category on button click
  var $_filterButtons = $(".gallery__filter-buttons button");

  $_filterButtons.on("click", function () {
    const catUrl = $(this).data("category-url");
    window.location.href = catUrl;
  });
}

function initHomepageFAQ() {
  if ($(".FAQ-schema").length === 0) {
    return;
  }
  // Generate schema
  var schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  };
  $(".FAQ-schema").each(function (i, e) {
    var data = e.dataset;
    schema.mainEntity.push({
      "@type": "Question",
      name: data.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: data.answer,
      },
    });
  });

  // Create element and append to body
  var schemaElement = document.createElement("script");
  schemaElement.type = "application/ld+json";
  schemaElement.text = JSON.stringify(schema);
  document.querySelector("body").appendChild(schemaElement);
}

// Recaptcha
function findFormFromToken(token) {
  var form = null;
  $(".recaptchaForm").each(function () {
    var r = $(this).find('textarea[name="g-recaptcha-response"]').val();
    if (r == token) {
      form = this;
    }
  });

  return form;
}

function contactFormCaptchaCallback(token) {
  console.log("SENDING CONTACT");
  // Identify form
  var form = findFormFromToken(token);

  // Validate form
  if (!$(form).valid()) {
    return;
  }

  // Show loading
  $(form).find(".contact__loading").removeClass("hidden");

  // Get settings
  var data = $(form).find("#contact-data").data();

  // Submit request
  $.ajax({
    url: data.redirect,
    type: "POST",
    headers: {
      "X-CSRFToken": data.csrfToken,
    },
    data: $(form).serializeArray(),
    error: function (jqXHR, textStatus, errorThrown) {
      // Hide loading
      $(form).find(".contact__loading").addClass("hidden");
      $(form)
        .find(".contact__message")
        .html(
          "That didn't work. Please try again.<br>" +
            "<a id='try-again' class='contact__form-submit button'>Try again</a>"
        );
      $("#try-again").click(function () {
        $(".contact__message").addClass("hidden");
      });
      $(form).find(".contact__message").removeClass("hidden");
    },
    success: function (data) {
      // Send a GA Event
      gtag("event", "Submit", {
        event_category: "Enquiry Form",
        event_label: window.location.pathname,
      });

      // Show success message
      $(form).find(".contact__loading").addClass("hidden");
      $(form)
        .find(".contact__message")
        .text(
          "Thank you for your message, we will get back to you as " +
            "soon as possible."
        );
      $(form).find(".contact__message").removeClass("hidden");
    },
  });
}

function submitRecaptchaForm(token) {
  // Identify form
  var form = findFormFromToken(token);
  // Validate form
  if (!$(form).valid()) {
    return;
  }
  form.submit();
}

function recaptchaOnload() {
  // Render captcha on submit button on each .recaptchaForm
  $(".recaptcha").each(function () {
    var gr_id = grecaptcha.render(
      this,
      {
        sitekey: "6LeG19gUAAAAAGhTJnpVUznRkWYNtKBYB14Y6YKJ",
        size: "invisible",
      },
      (inherit = true)
    );
  });

  $(".recaptchaForm").submit(function (e) {
    // Prevent default
    e.preventDefault();
    // Reset and execute grecaptcha
    grecaptcha.reset();
    grecaptcha.execute();
  });
}

function initSupport() {
  $(".support-popup").click(function (e) {
    e.preventDefault();
    window.open(
      "https://tawk.to/chat/5e4be837a89cda5a18869fbe/default",
      "Chat",
      "width=500,height=500,toolbar=0,resizable=0"
    );
    return false;
  });
}

function initCurrency() {
  // Changes pricing page depending on where a person is
  if ($(".pricing-table").length === 0) {
    return;
  }

  // Set pricing
  var pricing = {
    GBP: { premium: "£69", small_group: "£59", large_group: "£49" },
    EUR: { premium: "€80", small_group: "€70", large_group: "€60" },
    USD: { premium: "$100", small_group: "$85", large_group: "$70" },
  };
  europe_locs = [
    "AD",
    "AL",
    "AM",
    "AT",
    "AX",
    "AZ",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GB",
    "GE",
    "GG",
    "GI",
    "GR",
    "HR",
    "HU",
    "IE",
    "IM",
    "IS",
    "IT",
    "JE",
    "KZ",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "ME",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RS",
    "RU",
    "SE",
    "SI",
    "SJ",
    "SK",
    "SM",
    "TR",
    "UA",
    "VA",
  ];

  // Decide which currency to show
  var location = $("#ip-location").val();
  if (location == "GB") {
    var cur = "GBP";
  } else if (europe_locs.indexOf(location) > -1) {
    var cur = "EUR";
  } else {
    var cur = "USD";
  }

  // Rewrite prices on page
  $(".premium_price").each(function () {
    $(this).text(pricing[cur]["premium"]);
  });
  $(".small_group").each(function () {
    $(this).text(pricing[cur]["small_group"]);
  });
  $(".large_group").each(function () {
    $(this).text(pricing[cur]["large_group"]);
  });
}

function initTracking() {
  // Anything in app is tracked on backend, including newsletters & contact form
  // Track phone links
  $('a[href^="tel:"]').on("click", function () {
    gtag("event", "Click", {
      event_category: "Telephone Link",
      event_label: $(this).attr("href"),
    });
    return true;
  });
  // Track mail links
  $('a[href^="mailto:"]').on("click", function () {
    gtag("event", "Click", {
      event_category: "Email Link",
      event_label: $(this).attr("href"),
    });
    return true;
  });
  // Track pdf links
  $('a[href*=".pdf"]').on("click", function () {
    gtag("event", "Click", {
      event_category: "PDF Link",
      event_label: $(this)
        .attr("href")
        .replace(/\?.*?$/, ""),
    });
    return true;
  });
  // Track external links
  $('a[href^="http"]')
    .not('[href*="hotelbenchmarking.com"]')
    .not('[href*="storage.googleapis.com"]')
    .on("click", function () {
      gtag("event", "Click", {
        event_category: "External Link",
        event_label: $(this).attr("href"),
      });
      return true;
    });

  // Track scroll depth events via https://scrolldepth.parsnip.io/
  // (manually included in /markup/js/vendors/ as npm doesn't have latest version)
  jQuery.scrollDepth({
    userTiming: false,
    pixelDepth: false,
    gtmOverride: true,
  });
}

function initForms() {
  $(".contact__form-title select").selectric();

  $(".validate-registration").validate({
    rules: {
      password_confirm: {
        equalTo: "#password",
      },
    },
    errorPlacement: function (label, element) {
      if (element.is('input[type="checkbox"]')) {
        label.insertAfter(element.next("label"));
      } else {
        label.insertAfter(element);
      }
    },
  });

  $(".validate-this").validate();
}

function initAccordioms() {
  if ($("#accordion").length == 0) {
    return;
  }
  // Enable Accordiom
  $("#accordion").accordiom({
    showFirstItem: false,
  });

  // Open row if required by hash in URL
  $(window).on("hashchange load", function (e) {
    if (window.location.hash) {
      $(window.location.hash).next().trigger("click");
    }
  });
}

function initSticky() {
  if (!$("body.no-slider").length > 0) {
    $(window).on("scroll", function () {
      var scrollOffset = $(window).scrollTop();

      if (scrollOffset >= 5) {
        $(".header").addClass("fixed");
      } else {
        $(".header").removeClass("fixed");
      }
    });
  }
}

function initSliders() {
  $(".slider__inner").reformSlider({
    autoplay: false,
    arrows: true,
    dots: false,
  });

  $(".home-middle-panel__slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    fade: false,
    cssEase: "linear",
  });

  $(".home-bottom-panel__slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow:
      "<button type='button' class='slick-arrow slick-prev pull-left'></button>",
    nextArrow:
      "<button type='button' class='slick-arrow slick-next pull-left'></button>",
  });

  // Don't include Django-CMS Edit panel mess in the slider
  $(".reviews__slider").slick({
    arrows: true,
    dots: true,
  });

  $(".product-slider__slider").slick({
    arrows: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  // set Equal Height on Slider items

  function setEqualheight() {
    var content = $(".product-slider__content");
    var buttons = $(".product-slider__item .multiple-buttons");
    
  
    function getMaxHeight (array) {
      
      var heightsArr = [];

      array.each(function () {
        heightsArr.push($(this).height());
      });
      var arr = Object.keys(heightsArr).map(function (key) {
        return heightsArr[key];
      });
     
      var max = Math.max.apply(null, arr);
      return max + 15
    }

    var maxContent = getMaxHeight(content)
    var maxButton = getMaxHeight(buttons)
  
    content.each(function () {
      $(this).css("height", maxContent);
    });
    buttons.each(function () {
      $(this).css("height", maxButton);
    })
  }
  setEqualheight()

  $(".reviews__slider").slick("slickFilter", ":not(script, .cms-placeholder)");
}

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function initNotifications() {
  if ($(".header__notification-banner").length > 0) {
    var messageId = $(".header__notification-banner").data("message-id") || "0";

    // Show the banner if the hide cookie is not set
    if (
      typeof Cookies.get("hide_notification_banner-" + messageId) ===
      "undefined"
    ) {
      $(".header__notification-banner").show();
    }

    // Close the banner and set the don't show cookie
    $(".header__notification-banner-close").on("click", function () {
      $(this).parent().hide();
      Cookies.set("hide_notification_banner-" + messageId, "true", {
        expires: 7,
      });
    });
  }

  $(".header__notification-alert-close").on("click", function () {
    hideAlertNotification($(this).parent());
  });

  $("[data-auto-fade]").each(function () {
    var $alertContainer = this;
    setTimeout(function () {
      hideAlertNotification($alertContainer);
    }, 7000);
  });
}

function hideAlertNotification(alertElement) {
  var $alertContainer = $(alertElement);
  $alertContainer.fadeOut(function () {
    $alertContainer.remove();
  });
}

function initHomeDemoReport() {
  $(".home-intro__circle-figure").each(function () {
    var chars = $(this).data("figure").toString().split("");
    var output = "";
    var digitCount = 1;

    for (var i = 0; i < chars.length; i++) {
      if (isNaN(chars[i])) {
        output += chars[i];
      } else {
        output += '<span data-class="animate-to-{{x}}">';
        var digitStep = 0;

        // Generate the numbers to animate through
        // First of all, each column of numbers needs a progressivly larger number of 0-9 loops
        var j = 0;
        while (j < digitCount) {
          for (var k = 0; k < 10; k++) {
            output += k + "<br>";
            digitStep++;
          }
          j++;
        }

        // Then generate 0-x for whatever the curent digit is
        j = 0;
        while (j <= chars[i]) {
          output += j + "<br>";
          digitStep++;
          j++;
        }

        digitCount++;

        output += "</span>";
        output = output.replace("{{x}}", digitStep - 1);
      }
    }

    $(this).html(output);
  });

  // Now add the class which triggers the animation
  setTimeout(function () {
    $(".home-intro__circle-figure span").each(function () {
      $(this).attr("class", $(this).data("class"));
    });
  }, 750);

  $(".home-intro__scroll button").on("click", function (e) {
    e.preventDefault();

    $("html, body").animate(
      {
        scrollTop:
          $(".home-intro__bottom-content-inner").offset().top -
          $(".header").outerHeight() +
          20,
      },
      1000
    );
  });
}

function initReportWidget() {
  // Report widget JSON config example can be found in
  // /website/utils/tests/files/report_widget_config.json
  var form = $("#report-widget-form");
  if (form.length === 0) {
    return;
  }

  form.on("submit", function (e) {
    e.preventDefault();
    // Get data from form and find relevant averages
    var data = JSON.parse($("#widget-vals").text());
    var info = JSON.parse($("#widget-info").text());
    numeral.locale(info.locale);
    numeral.options.scalePercentBy100 = false;
    var setup = form.serializeArray();
    var inputString = setup
      .filter(function (e) {
        return e.name.includes("in-") ? true : false;
      })
      .map(function (e) {
        return e.value;
      })
      .join("|");
    var avgs = data[inputString];

    // Optionally put user values into circles
    var userValues = setup.filter(function (e) {
      return e.name.includes("user-") ? true : false;
    });
    userValues.forEach(function (each, i) {
      var el = $("#out-" + String(each.name));
      if (each.value) {
        var printValue = numeral(each.value).format(info.formats[i]);
        el.text(printValue);
        // Decide on colour
        if (each.value > avgs[i]) {
          el.addClass("green");
          el.removeClass("red");
        } else {
          el.addClass("red");
          el.removeClass("green");
        }
        if (info.reverse[i]) {
          el.toggleClass("red");
          el.toggleClass("green");
        }
        el.parent().removeClass("hidden");
      } else {
        el.parent().addClass("hidden");
      }
    });

    // Put averages into circles and unhide
    for (var i = 0; i < avgs.length; i++) {
      var el = $("#out-" + String(i));
      var printValue = numeral(avgs[i]).format(info.formats[i]);
      el.text(printValue);
    }
    $("#report-widget-results").removeClass("hidden");
  });
}
